<template>
  <div class="chart">
    <chart
      ref="chart"
      :chartData="chartData"
      :chartOptions="chartOptions"
      :chartType="chartType"
      :chartId="chartId"
      :plugins="plugins"
    />
  </div>
</template>
<script>
import BaseChart from "./BaseChart.vue";
// import moment from "moment";
export default {
  components: {
    chart: BaseChart,
  },
  props: {
    chartId: String,
    chartData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    title: String,
  },
  data() {
    return {
      chartType: "bar",
      chartOptions: {
        responsive: true,
        pointRadius: 0,
        interaction: {
          mode: "index",
          intersect: false,
        },
        stacked: false,
        scales: {
          x: {
            display: true,
            grid: {
              borderColor: "transparent",
              color: "transparent",
              drawBorder: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              color: "#fff",
              crossAlign: "near",
              font: {
                family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                size: 14,
              },
            },
          },
          y1: {
            display: true,
            grid: {
              borderColor: "#FFFFFF",
              drawBorder: false,
              color: "#112138",
              drawTicks: false,
              lineWidth: 3,
            },
            ticks: {
              color: "#fff",
              crossAlign: "near",
              font: {
                family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                size: 14,
              },
              stepSize: 5,
            },
            position: "left",
          },
          y2: {
            display: true,
            grid: {
              borderColor: "#FFFFFF",
              drawBorder: false,
              color: "#112138",
              drawTicks: false,
              lineWidth: 3,
            },
            ticks: {
              color: "#fff",
              crossAlign: "near",
              font: {
                family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                size: 14,
              },
              stepSize: 5,
            },
            position: "right",
            // suggestedMax: 10,
          },
        },
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "bottom",
            align: "start",
            color: "#fff",
            reverse: true,
            fullSize: false,
            labels: {
              pointStyle: "circle",
              usePointStyle: true,
              boxWidth: 7,
              boxHeight: 7,
              color: "#fff",
              font: {
                family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                size: 16,
                lineHeight: 1.8,
                // weight: 500,
              },
              filter: (legendItem) => {
                if (legendItem.datasetIndex == 0) {
                  legendItem.fillStyle = "#127A80";
                } else {
                  legendItem.fillStyle = "#E0F2BF";
                }

                return true;
              },
            },
          },
          tooltip: {
            enabled: true,
          },
          datalabels: {
            display: false,
          },
          custom_title: {},
        },
      },
      plugins: [
        {
          id: "custom_title",
          afterDraw: (chart) => {
            var ctx = chart.ctx;
            ctx.save();
            ctx.textAlign = "right";
            ctx.font = "16px Helvetica Neue";
            ctx.fillStyle = "#9A9A9A";
            ctx.fillText(this.title, chart.chartArea.right + 22, chart.legend.top + 20);
            ctx.restore();
          },
        },
      ],
    };
  },
};
</script>

<style></style>
