<template>
  <div class="battery-plan">
    <v-row class="status-bar">
      <v-col cols="3" lg="3" md="12" sm="12">
        <span>
          Region {{ status_bar.region }}
          <span class="upper">{{ this.status_bar.site }}</span></span
        >
        <button class="date-btn">TODAY</button>
      </v-col>
      <v-col cols="9" lg="9" md="12" sm="12">
        <div class="status-bar-content">
          <div
            v-for="(data, index) in status_bar.globalData"
            :key="index"
            class="sys-info"
          >
            <span class="green-color2 status-info">{{ data.label }}&nbsp;&nbsp;</span>
            <span class="status-info">{{ data.value }}</span>
          </div>
          <div class="date-info">
            {{ status_bar.time }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="content">
      <!-- cols="3" lg="3" md="12" sm="12" -->
      <v-col cols="3" lg="3" md="12" sm="12" class="left-panel">
        <div class="session enegy">
          <div class="session-title">Energy management Unit 12345</div>
          <div class="session-content">
            <div class="col col-12 update-time">
              <v-row>
                <!-- <div class="col col-9">
                  Last updated: {{ enegy.updated_at }}
                </div>
                <div class="left-auto">
                  <span class="circle"></span>
                  <span class="status">{{ enegy.status }}</span>
                </div> -->
                <v-col cols="9" lg="9" md="8" sm="12">
                  Last updated: {{ enegy.updated_at }}
                </v-col>
                <v-col cols="3" lg="3" md="4" sm="12" class="status-info">
                  <div class="left-auto">
                    <span class="circle"></span>
                    <span class="status">{{ enegy.status }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="underline"></v-row>
            </div>
            <div class="col col-12 batteries">
              <v-row>
                <!-- <v-col cols="7">
                  Batteries
                </v-col>
                <div class="status-info right">
                  Status:
                  <span class="status">{{ enegy.batteries.status }}</span>
                </div> -->
                <v-col cols="8" lg="8" md="8" sm="12"> Batteries </v-col>
                <v-col cols="4" lg="4" md="4" sm="12" class="status-info">
                  <span class="left-auto">
                    Status:
                    <span class="status">{{ enegy.batteries.status }}</span>
                  </span>
                </v-col>
              </v-row>
              <v-row class="underline"></v-row>
              <v-row class="summary-battery-table">
                <v-col cols="3">
                  <div>Total</div>
                  <div class="unit">
                    {{ enegy.batteries.total }} {{ enegy.batteries.total_unit }}
                  </div>
                </v-col>
                <v-col cols="4">
                  <div>Avg Voltage</div>
                  <div class="unit">
                    {{ enegy.batteries.avg_voltage }}
                    {{ enegy.batteries.avg_voltage_unit }}
                  </div>
                </v-col>
                <v-col cols="5">
                  <div>Avg Capacity</div>
                  <div class="unit">
                    {{ enegy.batteries.avg_capacity
                    }}{{ enegy.batteries.avg_capacity_unit }}
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="col col-12 system">
              <v-row>
                <v-col cols="8" lg="8" md="8" sm="12"> Power Conditioning System </v-col>
                <v-col cols="4" lg="4" md="4" sm="12" class="status-info">
                  <span class="left-auto">
                    Status:
                    <span class="status">{{ enegy.system.status }}</span>
                  </span>
                </v-col>
              </v-row>
              <v-row class="underline"></v-row>
              <v-row class="summary-system-table">
                <v-col cols="3">
                  <div>Total</div>
                  <div class="unit">
                    {{ enegy.system.total }} {{ enegy.system.total_unit }}
                  </div>
                </v-col>
                <v-col cols="4">
                  <div>Avg Voltage</div>
                  <div class="unit">
                    {{ enegy.system.avg_voltage }}
                    {{ enegy.system.avg_voltage_unit }}
                  </div>
                </v-col>
                <v-col cols="5">
                  <div>Power</div>
                  <div class="unit">
                    {{ enegy.system.power }}{{ enegy.system.power_unit }}
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <div class="session detail-enegy">
          <div class="power-session">
            <div class="session-title">Power Conditioning System</div>
            <div class="session-content">
              <v-row>
                <!-- <v-col cols="5" class="table-header">
                  <div>Name</div>
                  <div>Status</div>
                  <div>Power (kW)</div>
                  <div>Temperature(C)</div>
                  <div>Grid Voltage(V)</div>
                </v-col>
                <v-col cols="7">
                  <v-row>
                    <v-col
                      cols="4"
                      v-for="(data, i) in powerList"
                      :key="i"
                      :value="data"
                      class="table-content"
                    >
                      <div>{{ data.name }}</div>
                      <div>{{ data.status }}</div>
                      <div>{{ data.power }}</div>
                      <div>{{ data.temperature }}</div>
                      <div>
                        {{ data.grid_voltage ? data.grid_voltage : "NA" }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col> -->
                <v-col cols="12" lg="12" md="12" sm="12">
                  <v-row v-for="(data, i) in powerHeaderText" :key="i" :value="data">
                    <v-col cols="5" lg="5" md="5" sm="5" class="table-header">
                      {{ data.text }}
                    </v-col>
                    <v-col cols="7" lg="7" md="7" sm="7" class="table-body">
                      <v-row>
                        <v-col
                          cols="4"
                          v-for="(power, i) in powerList"
                          :key="i"
                          :value="power"
                          class="table-content"
                        >
                          <div :class="data.key">{{ power[data.key] }}</div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="underline"></v-row>
              <v-row class="table-paging">
                <v-col cols="12" style="display: flex; justify-content: flex-end">
                  <div class="previous-btn">
                    <button @click="prevPowerPage"></button>
                  </div>
                  <div class="paging-content">
                    <button
                      @click="changePowerPage(i)"
                      v-for="i in powerPage"
                      :key="i"
                      :class="i == currentPowerPage ? 'active' : ''"
                    >
                      {{ i }}
                    </button>
                  </div>
                  <div class="next-btn">
                    <button @click="nextPowerPage"></button>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="battery-session">
            <div class="session-title">Battery Management System</div>
            <div class="session-content">
              <!-- batteryHeaderText -->
              <v-row>
                <v-col cols="12" lg="12" md="12" sm="12">
                  <v-row v-for="(data, i) in batteryHeaderText" :key="i" :value="data">
                    <v-col cols="5" lg="5" md="5" sm="5" class="table-header">
                      {{ data.text }}
                    </v-col>
                    <v-col cols="7" lg="7" md="7" sm="7" class="table-body">
                      <v-row>
                        <v-col
                          cols="4"
                          v-for="(battery, i) in batteryList"
                          :key="i"
                          :value="battery"
                          class="table-content"
                        >
                          <div :class="data.key">{{ battery[data.key] }}</div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="underline"></v-row>
              <v-row class="table-paging">
                <v-col cols="12" style="display: flex; justify-content: flex-end">
                  <div class="previous-btn">
                    <button @click="prevBatteryPage"></button>
                  </div>
                  <div class="paging-content">
                    <button
                      @click="changeBatteryPage(i)"
                      v-for="i in batteryPage"
                      :key="i"
                      :class="i == currentBatteryPage ? 'active' : ''"
                    >
                      {{ i }}
                    </button>
                  </div>
                  <div class="next-btn">
                    <button @click="nextBatteryPage"></button>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="9" lg="9" md="12" sm="12" class="main-panel">
        <v-col cols="12">
          <v-row class="session power-metric">
            <v-col cols="12">
              <v-row class="chart-title">Power Metrics</v-row>
              <v-row>
                <v-col cols="12">
                  <line-chart
                    chartId="power-chart"
                    :chartData="powerMetricChart"
                    ref="powerChart"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-row class="session small-chart">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="9" lg="8" md="6" sm="6" class="chart-title"
                      >Waste to Energy</v-col
                    >
                    <v-col cols="3" lg="4" md="6" sm="6" class="select-box">
                      <div class="dropdown">
                        <v-select
                          :items="monthYearItems"
                          outlined
                          class="drd-month-year"
                          item-text="name"
                          item-value="value"
                          flat
                          v-model="wasteBaseOn"
                          ref="wasteSelect"
                        >
                        </v-select>
                      </div>
                      <span class="icon"></span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <multi-axis-y-line-chart
                        chartId="waste-chart"
                        :chartData="wasteToEnergyChart"
                        :title="wasteToEnergyChart.title"
                        ref="wasteToEnergyChart"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row class="session small-chart vehicle-chart">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="9" lg="8" md="6" sm="6" class="chart-title"
                      >Vehicle to Building</v-col
                    >
                    <v-col cols="3" lg="4" md="6" sm="6" class="select-box">
                      <div class="dropdown">
                        <v-select
                          :items="monthYearItems"
                          outlined
                          class="drd-month-year"
                          item-text="name"
                          item-value="value"
                          flat
                          v-model="vehicleBaseOn"
                          ref="vehicleSelect"
                        ></v-select>
                      </div>
                      <span class="icon"></span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <multi-axis-y-line-chart
                        chartId="vehicle-chart"
                        :chartData="vehicleToBuildingChart"
                        :title="vehicleToBuildingChart.title"
                        ref="vehicleToBuildingChart"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss" src="../../assets/css/battery.scss"></style>
<script>
import moment from "moment";
import { batteryService } from "../../services";
import LineChart from "../../components/LineChart.vue";
// import MultiAxisLineChart from "../../components/MultiAxisYLineChart.vue";
import MultiAxisYLineChart from "../../components/MultiAxisYLineChart.vue";
import { monthYearItems } from "../../contants";
export default {
  name: "BatteryPlantMangement",
  components: {
    LineChart,
    // MultiAxisYLineChart,
    MultiAxisYLineChart,
  },
  data: () => ({
    option1: { plugins: { title: { text: "aaaaaa" } } },
    status_bar: {
      globalData: [
        { label: "SYSTEM ONLINE", value: "888/2122 Online" },
        { label: "TOTAL PV GENERATED", value: "7.10 kWh(Today)" },
        { label: "TOTAL BATTERY CAPACITY", value: "6.24/6.24 kWh" },
      ],
      region: "1234",
      site: "all sites",
      time: null,
      interval: null,
    },
    enegy: {
      batteries: {
        status: "normal",
        total: 4,
        avg_v: 15,
        avg_c: 85,
      },
      system: {
        status: "normal",
        total: 4,
        avg_v: 17.2,
        power: 5,
      },
      update_at: moment("2021-07-04").format("MMMM DD, YYYY"),
      status: "Online",
    },
    powerHeaderText: [
      { text: "", key: "name" },
      { text: "Status", key: "status" },
      { text: "Power (kW)", key: "power" },
      { text: "Temperature(C)", key: "temperature" },
      { text: "Grid Voltage(V)", key: "grid_voltage" },
    ],
    batteryHeaderText: [
      { text: "", key: "name" },
      { text: "Status", key: "status" },
      { text: "Power (kW)", key: "power" },
      { text: "Temperature(C)", key: "temperature" },
      { text: "SOC(%)", key: "soc" },
      { text: "DC Bus Voltage(V)", key: "dc_bus_voltage" },
    ],
    power: [
      {
        name: "PS1",
        status: "online",
        power: "120",
        temp: "45.5",
        volt: null,
      },
      {
        name: "PS2",
        status: "online",
        power: "120",
        temp: "45.5",
        volt: null,
      },
      {
        name: "PS3",
        status: "online",
        power: "120",
        temp: "45.5",
        volt: null,
      },
      {
        name: "PS4",
        status: "online",
        power: "120",
        temp: "45.5",
        volt: null,
      },
    ],
    batteries: [
      {
        name: "BMS1",
        status: "online",
        power: "123",
        temp: "30",
        soc: "85",
        volt: "865.5",
      },
      {
        name: "BMS2",
        status: "online",
        power: "123",
        temp: "30",
        soc: "85",
        volt: "865.5",
      },
      {
        name: "BMS3",
        status: "online",
        power: "123",
        temp: "30",
        soc: "85",
        volt: "865.5",
      },
      {
        name: "BMS4",
        status: "online",
        power: "123",
        temp: "30",
        soc: "85",
        volt: "865.5",
      },
    ],
    currentPowerPage: 1,
    currentBatteryPage: 1,
    site_id: 1,
    baseOn: "month",
    powerMetricChart: {
      labels: [],
      datasets: [],
    },
    wasteToEnergyChart: {
      labels: [],
      datasets: [],
      title: "",
    },
    vehicleToBuildingChart: {
      labels: [],
      datasets: [],
      title: "",
    },
    minutes: [0],
    monthYearItems,
    wasteBaseOn: "month",
    vehicleBaseOn: "month",
  }),
  methods: {
    getData() {
      this.getEnergyManagementUnit(this.site_id);

      this.getPowerConditioningSystem(this.site_id);

      this.getBatteryManagementSystem(this.site_id);

      this.getPowerMetric(this.site_id);

      this.getWasteToEnergy(this.site_id, this.wasteBaseOn);

      this.getVehicleToBuilding(this.site_id, this.baseOn);
    },

    getEnergyManagementUnit(site_id) {
      batteryService.getEnergyManagementUnit(site_id).then((res) => {
        if (res?.data?.data?.data) {
          this.setEnergyManagementUnit(res.data.data.data);
        }
      });
    },
    setEnergyManagementUnit(data) {
      const { batteries, power_conditioning_system, status, updated_at } = data;
      this.enegy = {
        status,
        updated_at,
        batteries,
        system: power_conditioning_system,
      };
    },
    getPowerConditioningSystem(site_id) {
      batteryService.getPowerConditioningSystem(site_id).then((res) => {
        if (res?.data?.data?.data) {
          this.setPowerConditioningSystem(res.data.data.data);
        }
      });
    },
    setPowerConditioningSystem(data) {
      this.power = data;
    },
    getBatteryManagementSystem(site_id) {
      batteryService.getBatteryManagementSystem(site_id).then((res) => {
        if (res?.data?.data?.data) {
          this.setBatteryManagementSystem(res.data.data.data);
        }
      });
    },
    setBatteryManagementSystem(data) {
      this.batteries = data;
    },

    getPowerMetric(site_id) {
      batteryService.getPowerMetric(site_id).then((res) => {
        if (res?.data?.data?.data) {
          this.setPowerMetric(res.data.data.data);
        }
      });
    },
    setPowerMetric(data) {
      const labels = Object.keys(data.power_1.data);
      const current_hour = moment().hour();

      const chartData = {
        labels: [],
        datasets: [],
        chartArea: {
          backgroundColor: "#fff",
        },
      };
      // labels.push("24:00");
      // labels.push(moment().add(1,'hour').format("HH:mm"))

      const power1 = {
        label: "Power 1",
        borderColor: "#788cff",
        backgroundColor: "#788cff",
        data: [],
      };

      const power2 = {
        label: "Power 2",
        borderColor: "#f9f382",
        backgroundColor: "#f9f382",
        data: [],
      };

      const power3 = {
        label: "Power 3",
        borderColor: "#fedeff",
        backgroundColor: "#fedeff",
        data: [],
      };
      labels.map((val) => {
        const minute = moment(val, "HH:mm").minute();
        const hour = moment(val, "HH:mm").hour();
        if (hour == current_hour) {
          power1.data.push(this.conertData(data.power_1.data[val]) || 0);
          power2.data.push(this.conertData(data.power_2.data[val]) || 0);
          power3.data.push(this.conertData(data.power_3.data[val]) || 0);
          if (minute % 5 == 0) {
            chartData.labels.push(val);
          }
        }
      });

      chartData.labels.push(`${current_hour + 1}:00`);
      chartData.datasets.push(power1);
      chartData.datasets.push(power2);
      chartData.datasets.push(power3);

      this.$refs.powerChart.$refs.chart.updateChart(chartData);
    },

    conertData(data) {
      if (typeof data == "string") {
        const lastChar = data.substr(data.length - 1).toLowerCase();
        let add = 1;
        switch (lastChar) {
          case "k":
            add = 1000;
            break;
          case "m":
            add = 1000000;
            break;
          case "b":
            add = 1000000000;
            break;
          default:
            break;
        }

        return parseInt(data) * add;
      } else {
        return data;
      }
      // if(isNaN(data)){
      //   var lastChar = id.substr(id.length - 1);
      // } else {
      //   return data;
      // }
    },

    getWasteToEnergy(site_id, baseOn) {
      batteryService.getWasteToEnergy(site_id, baseOn).then((res) => {
        if (res?.data?.data?.data) {
          this.setWasteToEnergy(res.data.data.data);
        }
      });
    },
    setWasteToEnergy(data) {
      const { waste_input, waste_output, output_electricity, current_time } = data;

      this.wasteToEnergyChart.title = current_time;
      const labels = Object.keys(waste_input.data);
      labels.sort((a, b) => {
        const int_a = parseInt(a);
        const int_b = parseInt(b);
        if (int_a > int_b) return 1;
        else if (int_a < int_b) return -1;
        return 0;
      });

      var wasteChart = document.getElementById("waste-chart");
      var ctx = wasteChart.getContext("2d");

      var gradientInput = ctx.createLinearGradient(0, 0, 0, 400);
      gradientInput.addColorStop(0, "#016469");
      gradientInput.addColorStop(1, "#08CDD6");

      var gradientOutput = ctx.createLinearGradient(0, 0, 0, 400);
      gradientOutput.addColorStop(0, "#CBFFFC");
      gradientOutput.addColorStop(1, "#37A7D7");

      const waste_input_data = {
        label: waste_input.name,
        backgroundColor: gradientInput,
        type: "bar",
        borderRadius: 50,
        borderSkipped: false,
        barThickness: 9,
        borderWidth: 1,
        order: 1,
        yAxisID: "y1",
        data: [],
      };

      const waste_output_data = {
        label: waste_input.name,
        // borderColor: "#A7EAF3",
        backgroundColor: gradientOutput,

        type: "bar",
        borderRadius: 50,
        borderSkipped: false,
        barThickness: 9,
        borderWidth: 1,
        order: 1,
        yAxisID: "y1",
        data: [],
      };

      const output_electricity_data = {
        label: output_electricity.name,
        borderColor: "#FEFF5C",
        backgroundColor: "#FEFF5C",
        order: 0,
        type: "line",
        yAxisID: "y2",
        data: [],
      };
      const chartData = {
        labels: [],
        datasets: [],
      };

      labels.forEach((val) => {
        waste_input_data.data.push(waste_input.data[val] || 0);
        waste_output_data.data.push(waste_output.data[val] || 0);
        output_electricity_data.data.push(output_electricity.data[val] || 0);
        chartData.labels.push(val);
      });
      chartData.datasets.push(waste_input_data);
      chartData.datasets.push(waste_output_data);
      chartData.datasets.push(output_electricity_data);
      this.$refs.wasteToEnergyChart.$refs.chart.updateChart(chartData);
    },

    getVehicleToBuilding(site_id, baseOn) {
      batteryService.getVehicleToBuilding(site_id, baseOn).then((res) => {
        if (res?.data?.data?.data) {
          this.setVehicleToBuilding(res.data.data.data);
        }
      });
    },
    setVehicleToBuilding(data) {
      const {
        ev_charged_energy,
        ev_discharge_energy,
        no_of_ev_online,
        current_time,
      } = data;
      this.vehicleToBuildingChart.title = current_time;
      const labels = Object.keys(ev_charged_energy.data);
      labels.sort((a, b) => {
        const int_a = parseInt(a);
        const int_b = parseInt(b);
        if (int_a > int_b) return 1;
        else if (int_a < int_b) return -1;
        return 0;
      });
      var vehicleChart = document.getElementById("vehicle-chart");
      var ctx = vehicleChart.getContext("2d");

      var gradientCharged = ctx.createLinearGradient(0, 0, 0, 400);
      gradientCharged.addColorStop(0, "#016469");
      gradientCharged.addColorStop(1, "#08CDD6");

      var gradientDischarge = ctx.createLinearGradient(0, 0, 0, 400);
      gradientDischarge.addColorStop(0, "#CBFFFC");
      gradientDischarge.addColorStop(1, "#37A7D7");

      const ev_charged_energy_data = {
        label: ev_charged_energy.name,
        backgroundColor: gradientCharged,
        type: "bar",
        borderRadius: 50,
        borderSkipped: false,
        barThickness: 9,
        borderWidth: 1,
        order: 1,
        yAxisID: "y1",
        data: [],
      };

      const ev_discharge_energy_data = {
        label: ev_discharge_energy.name,
        backgroundColor: gradientDischarge,
        type: "bar",
        borderRadius: 50,
        borderSkipped: false,
        barThickness: 9,
        borderWidth: 1,
        order: 1,

        yAxisID: "y1",
        data: [],
      };

      const no_of_ev_online_data = {
        label: no_of_ev_online.name,
        borderColor: "#FEFF5C",
        backgroundColor: "#FEFF5C",
        yAxisID: "y2",
        type: "line",
        order: 0,
        data: [],
      };
      const chartData = {
        labels,
        datasets: [],
      };

      labels.forEach((val) => {
        ev_charged_energy_data.data.push(ev_charged_energy.data[val] || 0);
        ev_discharge_energy_data.data.push(ev_discharge_energy.data[val] || 0);
        no_of_ev_online_data.data.push(no_of_ev_online.data[val] || 0);
      });

      chartData.datasets.push(ev_charged_energy_data);
      chartData.datasets.push(ev_discharge_energy_data);
      chartData.datasets.push(no_of_ev_online_data);
      this.$refs.vehicleToBuildingChart.$refs.chart.updateChart(chartData);
    },

    // this.getVehicleToBuilding(this.site_id, this.baseOn);

    currentDate: function () {
      return moment().format("ddd DD MMMM YYYY HH:mmA");
    },
    nextPowerPage() {
      if (this.currentPowerPage + 1 <= this.powerPage) {
        this.changePowerPage(this.currentPowerPage + 1);
      }
    },
    prevPowerPage() {
      if (this.currentPowerPage - 1 > 0) {
        this.changePowerPage(this.currentPowerPage - 1);
      }
    },
    changePowerPage(page) {
      if (this.currentPowerPage != page) {
        this.currentPowerPage = page;
      }
    },
    nextBatteryPage() {
      if (this.currentBatteryPage + 1 <= this.batteryPage) {
        this.changeBatteryPage(this.currentBatteryPage + 1);
      }
    },
    prevBatteryPage() {
      if (this.currentBatteryPage - 1 > 0) {
        this.changeBatteryPage(this.currentBatteryPage - 1);
      }
    },
    changeBatteryPage(page) {
      if (this.currentBatteryPage != page) {
        this.currentBatteryPage = page;
      }
    },
  },
  computed: {
    powerPage() {
      return parseInt(this.power.length / 3) + (this.power.length % 3 == 0 ? 0 : 1);
    },
    batteryPage() {
      return (
        parseInt(this.batteries.length / 3) + (this.batteries.length % 3 == 0 ? 0 : 1)
      );
    },
    powerList() {
      const start = (this.currentPowerPage - 1) * 3;
      let end = start + 3;
      if (end > this.power.length) {
        end = this.power.length;
      }
      return this.power.slice(start, end);
    },
    batteryList() {
      const start = (this.currentBatteryPage - 1) * 3;
      let end = start + 3;
      if (end > this.batteries.length) {
        end = this.batteries.length;
      }
      return this.batteries.slice(start, end);
    },
  },
  watch: {
    wasteBaseOn() {
      this.getWasteToEnergy(this.site_id, this.wasteBaseOn);
    },
    vehicleBaseOn() {
      this.getVehicleToBuilding(this.site_id, this.vehicleBaseOn);
    },
  },
  created() {
    this.interval = setInterval(() => {
      this.status_bar.time = moment().format("ddd D MMM YYYY   HH:mm");
    }, 1000);
    this.getData();
  },
};
</script>
