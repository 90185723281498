<template>
  <div class="chart">
    <chart
      ref="chart"
      :chartData="chartData"
      :chartOptions="chartOptions"
      :chartType="chartType"
      :chartId="chartId"
      :plugins="plugins"
    />
  </div>
</template>
<script>
import BaseChart from "./BaseChart.vue";
export default {
  components: {
    chart: BaseChart,
  },
  props: {
    chartId: String,
    chartData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      chartType: "line",
      chartOptions: {
        layout: {
          padding: {
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
          },
        },
        responsive: true,
        pointRadius: 0,
        interaction: {
          mode: "index",
          intersect: false,
        },
        stacked: false,
        chartArea: {
          backgroundColor: "rgba(255, 255, 255, 0.05)",
        },
        gridLines: {
          display: false,
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: "#BEE1B7",
              crossAlign: "far",
              font: {
                family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                size: 18,
                lineHeight: 2.3,
                weight: 500,
              },
            },
            afterFit:function(axis){
              const w = window.innerWidth;
              let padding = 50;
              let h = 65;
              if(w < 1280 && w > 1090){
                padding = 30
                h=45
              } else if (w < 1090){
                padding = 0;
                h = 60
              }
              axis.options.ticks.padding = padding;
              axis.height = h;
            }
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              color: "#406475",
              crossAlign: "far",
              font: {
                family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                size: 18,
                lineHeight: 2.3,
                weight: 500,
              },
              callback: function(value) {
                return Math.abs(value) > 999
                  ? Math.sign(value) * (Math.abs(value) / 1000).toFixed(1) + "k"
                  : value;
              },

              padding: 10,
              stepSize: 100000,
            },
          },
        },

        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            // backgroundColor: "red",
            external: function(context) {
              const { chart, tooltip } = context;
              // const tooltipEl = getOrCreateTooltip(chart);
              let tooltipEl = chart.canvas.parentNode.querySelector("div");

              if (!tooltipEl) {
                tooltipEl = document.createElement("div");
                tooltipEl.style.background = "#398390";
                tooltipEl.style.borderRadius = "10px";
                tooltipEl.style.color = "white";
                tooltipEl.style.opacity = 1;
                tooltipEl.style.pointerEvents = "none";
                tooltipEl.style.position = "absolute";
                tooltipEl.style.transform = "translate(-50%, 0)";
                tooltipEl.style.transition = "all .1s ease";

                const table = document.createElement("table");
                table.style.margin = "0px";

                tooltipEl.appendChild(table);
                chart.canvas.parentNode.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltip.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set Text
              if (tooltip.body) {
                const titleLines = tooltip.title || [];
                const bodyLines = tooltip.body.map((b) => b.lines);
                // console.log(bodyLines)
                const tableHead = document.createElement("thead");

                titleLines.forEach((title) => {
                  const tr = document.createElement("tr");
                  tr.style.borderWidth = 0;

                  const th = document.createElement("th");
                  th.style.borderWidth = 0;
                  th.style.textAlign = "left";
                  const text = document.createTextNode(title);

                  th.appendChild(text);
                  tr.appendChild(th);
                  tableHead.appendChild(tr);
                });

                const tableBody = document.createElement("tbody");
                bodyLines.forEach((body, i) => {
                  const colors = tooltip.labelColors[i];

                  const span = document.createElement("span");
                  span.style.background = colors.backgroundColor;
                  span.style.borderColor = colors.borderColor;
                  span.style.borderWidth = "2px";
                  span.style.marginRight = "10px";
                  span.style.height = "10px";
                  span.style.width = "10px";
                  span.style.display = "inline-block";

                  const tr = document.createElement("tr");
                  tr.style.backgroundColor = "inherit";
                  tr.style.borderWidth = 0;

                  const td = document.createElement("td");
                  td.style.borderWidth = 0;

                  const newBody = body[0].split(":");
                  let value = newBody[1].replace(",", "");

                  value =
                    Math.abs(value) > 999
                      ? Math.sign(value) * (Math.abs(value) / 1000).toFixed(1) +
                        "k"
                      : Math.sign(value) * Math.abs(value);
                  newBody[1] = value;
                  body[0] = newBody.join(": ");
                  const text = document.createTextNode(body);
                  // console.log(body);

                  // td.appendChild(span);
                  td.appendChild(text);
                  tr.appendChild(td);
                  tableBody.appendChild(tr);
                });

                const tableRoot = tooltipEl.querySelector("table");

                // Remove old children
                while (tableRoot.firstChild) {
                  tableRoot.firstChild.remove();
                }

                // Add new children
                tableRoot.appendChild(tableHead);
                tableRoot.appendChild(tableBody);
              }
              // var position = context.chart.canvas.getBoundingClientRect();

              const {
                offsetLeft: positionX,
                offsetTop: positionY,
              } = chart.canvas;

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.left =
                positionX + tooltip.caretX + tooltip.width + "px";
              tooltipEl.style.top =
                positionY + tooltip.caretY - tooltip.height + "px";
              tooltipEl.style.font = tooltip.options.bodyFont.string;
              tooltipEl.style.fontSize = "18px";
              tooltipEl.style.lineHeight = "22px";

              tooltipEl.style.padding = "13px 21px";
            },
          },
          datalabels: {
            display: false,
          },
          horizontalLine: [],
          custom_canvas_background_color: {},
        },
      },
      plugins: [
        {
          id: "custom_canvas_background_color",
          afterDraw: (chart) => {
            if (
              chart.config.options.chartArea &&
              chart.config.options.chartArea.backgroundColor
            ) {
              var ctx = chart.ctx;
              var chartArea = chart.chartArea;

              const top = chart.scales.y.getPixelForValue(0);
              ctx.save();
              ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
              ctx.fillRect(
                chartArea.left,
                chartArea.top,
                chartArea.right - chartArea.left,
                chartArea.bottom - chartArea.top
              );
              //zero line
              ctx.restore();
              ctx.save();
              ctx.strokeStyle = "#fff";
              ctx.beginPath();
              ctx.setLineDash([5, 5]);
              ctx.moveTo(chart.scales.x.left, top);
              ctx.lineTo(
                chart.scales.x.left + chartArea.right - chartArea.left,
                top
              );
              ctx.stroke();
              ctx.restore();

              //axis line
              ctx.save();
              ctx.strokeStyle = "#fff";
              ctx.beginPath();
              ctx.setLineDash([5, 5]);
              ctx.moveTo(
                chart.scales.x.left,
                chart.scales.y.top + chartArea.bottom - chartArea.top + 15
              );
              ctx.lineTo(
                chart.scales.x.left + chartArea.right - chartArea.left,
                chart.scales.y.top + chartArea.bottom - chartArea.top + 15
              );
              ctx.stroke();
              ctx.restore();
            }
          },
        },
      ],
    };
  },
  methods: {
    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },
  },
};
</script>

<style></style>
